<template>
  <v-app id="login" class="secondary">
    <v-content class="grad1">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-layout align-center justify-center>
              <img
                src="@/assets/pumas-brand-header.svg"
                alt="Vue Material Admin"
                width="180"
                height="180"
              />
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col sm="12" lg="6">
            <v-layout align-center justify-center>
              <v-img
                lazy-src="@/assets/slider_3.svg"
                src="@/assets/slider_3.svg"
              ></v-img>
            </v-layout>
          </v-col>
          <v-col sm="12" lg="5">
            <v-layout align-center justify-center class="text-center">
              <v-flex xs10>
                <div class="layout column align-center">
                  <h2>Lyv</h2>
                  <h4 class="flex my-4 theme--text">Precision Healthcare</h4>
                </div>
                <v-form ref="form" lazy-validation>
                  <v-text-field
                    :type="hidePasswords ? 'password' : 'text'"
                    :append-icon="
                      hidePasswords ? 'visibility_off' : 'visibility'
                    "
                    name="password"
                    label="New Password"
                    id="password"
                    :rules="passwordRule"
                    v-model="confirmPassword"
                    :error="error"
                    outlined
                    @click:append="hidePasswords = !hidePasswords"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'visibility_off' : 'visibility'
                    "
                    name="Confirmpassword"
                    label="Confirm Password"
                    id="Cpassword"
                    :rules="passwordRule"
                    v-model="password"
                    :error="error"
                    outlined
                    @click:append="hidePassword = !hidePassword"
                  />
                </v-form>

                <v-btn
                  class="theme--white"
                  color="#EF515B"
                  @click="setPassword"
                  :loading="loading"
                  >Change Password</v-btn
                >
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        top
        v-model="ui.infoSnackbar.state"
        :timeout="ui.infoSnackbar.timeout || 6000"
        :color="ui.infoSnackbar.color"
      >
        {{ ui.infoSnackbar.info }}
      </v-snackbar>
    </v-content>
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from "../../components/Snackbar";
import { Main } from "@/services/modules";
import { mapMutations } from "vuex";
export default {
  name: "login",
  data() {
    return {
      loading: false,
      password: "",
      hidePassword: true,
      hidePasswords: true,
      error: false,
      showResult: false,
      result: "",
      confirmPassword: "",
      passwordRule: [(v) => !!v || "Password is required"],
      ui: {
        retrieveLoading: false,
        infoSnackbar: {
          state: false,
          color: "error",
          info: null,
          timeout: 6000,
        },
      },
    };
  },
  components: {
    Snackbar,
  },
  mounted() {
    if (this.$store.getters.changePassword) {
      this.$router.push({ name: "Condition" });
    }
  },

  methods: {
    setPassword() {
      const password = this.password;
      const confirmPassword = this.confirmPassword;
      if (password != confirmPassword) {
        this.setSnack("Passwords are not matching");
      } else {
        Main.changePass({
          password: password,
        })
          .then((res) => {
            console.log(res.data);
            this.ui.retrieveLoading = false;
            this.setSnack("Password Changed Successfully");
            this.$store.dispatch("changePasswordForLogin");
            this.$store
              .dispatch("logout")
              .then(() => {
                localStorage.clear();
                this.$router.push({ name: "Login" });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            this.setSnack(error.data.message);
            console.log(error.data.message);
          });
      }
    },
    ...mapMutations({
      setSnack: "snackbar/setSnack",
    }),
  },
};
</script>

<style scoped lang="scss">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.grad1 {
  background: linear-gradient(120deg, #c2c4c5, #c2c4c5, #ebb9a9 85%);
  background-size: 200% 200%;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
}
@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
v-h1 {
  font-size: 5rem;
  line-height: 3.65rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
</style>
